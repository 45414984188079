import { CardProps } from "./typings";

export default function Card({ extras, title ="", children, className = "", extrasClassName = "" }: CardProps) {
    return (
        <div className={`card ${className}`}>
            {(extras || title) && (<div className="card-header flex items-center justify-between">
                <h5 className="mb-0 card-title">{title}</h5>
                <div className={`flex ${extrasClassName} gap-3`}>
                    {extras?.map((extra) => extra)}
                </div>
            </div>)}
            <div className={`card-body`}>
                {children}
            </div>
        </div>
    )
}

import Card from "./Card";
import TablePaginate from "../table/TablePaginate";
import { CardTablePropsInterface } from './typings';

export default function CardTable({ extras, title, columns, loading, datas, pagination, setPagination, tableClassName = "", className = "", extrasClassName = "" }: CardTablePropsInterface) {
    return <>
        <Card className={className} title={title} extras={extras} extrasClassName={extrasClassName}>
            <TablePaginate columns={columns} loading={loading} datas={datas} pagination={pagination} setPagination={setPagination} tableClassName={tableClassName} />
        </Card>
    </>
}

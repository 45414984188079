const fr = require("apexcharts/dist/locales/fr.json")
export const CONFIG_CHART = {
    chart: {
        locales: [fr],
        defaultLocale: 'fr',
        toolbar: {
            show: true,
        },
    }
}

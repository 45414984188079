import {useForm} from "react-hook-form";
import {APP_API_URL} from "../config/api";
import Card from "../components/card/Card";
import {useQueries} from "../hooks/useQueries";
import {useRef} from "react";
import {Form} from "../components/form/Form";
import SimpleButton from "../components/button/SimpleButton";
import {CancelButton} from "../components/button/CustomButton";
import {BiSearchAlt2} from "react-icons/bi";
import moment from "moment";
import {InputDate} from "../components/form/Input";
import ReactApexChart from "react-apexcharts";
import {CONFIG_CHART} from "../components/chart/configChart";

export default function DashboardPage() {
    /**
     * Hooks
     */
    const startDate = useRef(moment().subtract(7, "day").format("YYYY-MM-DD"));
    const endDate = useRef(moment().format("YYYY-MM-DD"));

    const {
        register,
        reset,
        handleSubmit,
        formState: {errors},
    } = useForm();

    /**
     * Query
     */

    const {data: clientTotalByMode, isLoading} = useQueries(
        APP_API_URL +
        `client-total-by-mode?paginate=0${
            startDate.current ? `&startDate=${startDate.current}` : ""
        }${endDate.current ? `&endDate=${endDate.current}` : ""}`,
        ["clientTotalByMode", startDate.current, endDate.current]
    );

    const {data: clientTotal} = useQueries(
        APP_API_URL +
        `client-total?paginate=0${
            startDate.current ? `&startDate=${startDate.current}` : ""
        }${endDate.current ? `&endDate=${endDate.current}` : ""}`,
        ["clientTotal", startDate.current, endDate.current]
    );
    const {data: clientVerifiedByMode} = useQueries(
        APP_API_URL +
        `client-verified-by-mode?paginate=0${
            startDate.current ? `&startDate=${startDate.current}` : ""
        }${endDate.current ? `&endDate=${endDate.current}` : ""}`,
        ["clientVerifiedByMode", startDate.current, endDate.current]
    );
    const {data: clientNotVerifiedByMode} = useQueries(
        APP_API_URL +
        `client-not-verified-by-mode?paginate=0${
            startDate.current ? `&startDate=${startDate.current}` : ""
        }${endDate.current ? `&endDate=${endDate.current}` : ""}`,
        ["clientNotVerifiedByMode", startDate.current, endDate.current]
    );
    const handleSubmitFilterForm = (values: any) => {
        startDate.current = values.startDate;
        endDate.current = values.endDate;
    };
    const handleCancelForm = () => {
        reset();
        startDate.current = "";
        endDate.current = "";
    };

    /**
     * Columns Table
     */

    /**
     * UI
     */
    return (
        <>
            <Card title={"Filtres"} extras={[]}>
                <Form
                    loading={isLoading}
                    onSubmit={handleSubmit(handleSubmitFilterForm)}
                >
                    <div className={"grid lg:grid-cols-4 md:grid-cols-2 gap-2"}>
                        <div>
                            <InputDate
                                label={"Date de début"}
                                register={register}
                                error={errors?.startDate}
                                name={"startDate"}
                                defaultValue={moment().subtract(7, "day").format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <InputDate
                                label={"Date de fin"}
                                register={register}
                                error={errors?.endDate}
                                name={"endDate"}
                                defaultValue={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div className={"flex items-end gap-2"}>
                            <SimpleButton
                                type={"submit"}
                                className="bg-soft-primary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center mt-2"
                            >
                                <BiSearchAlt2 className={"mr-2"}/> Filtrer
                            </SimpleButton>
                            <CancelButton onClick={handleCancelForm}/>
                        </div>
                    </div>
                </Form>
            </Card>

            <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
                <Card title="">
                    <div className="text-blue-600">TOTAL</div>
                    <div className="text-right w-full">
                        <div className="">
                            {clientTotal?.data.data.map((Element: any) => Element.Total)}
                        </div>
                    </div>
                </Card>
                <Card title="">
                    <div className="text-green-600">VÉRIFIÉ</div>
                    <div className="text-right w-full">
                        <div className="">
                            {clientTotal?.data.data.map(
                                (Element: any) => Element.is_verified
                            )}
                        </div>
                    </div>
                </Card>
                <Card title="">
                    <div className="text-red-600">NON VÉRIFIÉ</div>
                    <div className="text-right w-full">
                        <div className="">
                            {clientTotal?.data.data.map(
                                (Element: any) => Element.is_not_verified
                            )}
                        </div>
                    </div>
                </Card>
            </div>

            <div className="grid grid-cols-2 content-center">
                <Card title={"Total Abonnés"}>
                    <ReactApexChart
                        className=""
                        height="350px"
                        options={{
                            chart: CONFIG_CHART.chart,
                        }}
                        series={[
                            {
                                data: clientNotVerifiedByMode
                                    ? clientNotVerifiedByMode?.data.data.web
                                    : [],
                                name: "WEB",
                            },
                            {
                                data: clientTotalByMode
                                    ? clientTotalByMode?.data.data.sms
                                    : [],
                                name: "SMS",
                            },
                            {
                                data: clientTotalByMode
                                    ? clientTotalByMode?.data.data.facebook
                                    : [],
                                name: "FACEBOOK",
                            },
                            {
                                data: clientTotalByMode
                                    ? clientTotalByMode?.data.data.linkedin
                                    : [],
                                name: "LINKEDIN",
                            },
                        ]}
                        type={"line"}
                    />
                </Card>
                <Card title={"Total abonnés vérifiés"}>
                    <ReactApexChart
                        className=""
                        height="350px"
                        options={{
                            chart: CONFIG_CHART.chart,
                        }}
                        series={[
                            {
                                data: clientNotVerifiedByMode
                                    ? clientNotVerifiedByMode?.data.data.web
                                    : [],
                                name: "WEB",
                            },
                            {
                                data: clientVerifiedByMode
                                    ? clientVerifiedByMode?.data.data.sms
                                    : [],
                                name: "SMS",
                            },
                            {
                                data: clientVerifiedByMode
                                    ? clientVerifiedByMode?.data.data.facebook
                                    : [],
                                name: "FACEBOOK",
                            },
                            {
                                data: clientVerifiedByMode
                                    ? clientVerifiedByMode?.data.data.linkedin
                                    : [],
                                name: "LINKEDIN",
                            },
                        ]}
                        type={"line"}
                    />
                </Card>
                <Card title={"Total abonnés non vérifiés"}>
                    <ReactApexChart
                        className=""
                        height="350px"
                        options={{
                            chart: CONFIG_CHART.chart,
                        }}
                        series={[
                            {
                                data: clientNotVerifiedByMode
                                    ? clientNotVerifiedByMode?.data.data.web
                                    : [],
                                name: "WEB",
                            },
                            {
                                data: clientNotVerifiedByMode
                                    ? clientNotVerifiedByMode?.data.data.sms
                                    : [],
                                name: "SMS",
                            },
                            {
                                data: clientNotVerifiedByMode
                                    ? clientNotVerifiedByMode?.data.data.facebook
                                    : [],
                                name: "FACEBOOK",
                            },
                            {
                                data: clientNotVerifiedByMode
                                    ? clientNotVerifiedByMode?.data.data.linkedin
                                    : [],
                                name: "LINKEDIN",
                            },
                        ]}
                        type={"line"}
                    />
                </Card>
            </div>
        </>
    );
}

import {useForm} from "react-hook-form";
import CardTable from "../components/card/CardTable";
import {ColumnInterface} from "../components/table/typings";
import {APP_API_URL} from "../config/api";
import Card from "../components/card/Card";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import {useQueries} from "../hooks/useQueries";
import {useRef} from "react";
import {BadgeRounded} from "../components/badge/Badge";
import {Form} from "../components/form/Form";
import {Select2} from "../components/form/Select";
import SimpleButton from "../components/button/SimpleButton";
import {CancelButton} from "../components/button/CustomButton";
import {BiSearchAlt2} from "react-icons/bi";
import moment from "moment";
import {InputDate} from "../components/form/Input";

export default function ClientPage() {
    /**
     * Hooks
     */
    const {baseApiUrl, pagination, title, setPagination} =
        useHeaderFunctionPage({baseApiUrl: "client", title: "Liste des clients"});

    const town = useRef(null);
    const isVerified = useRef(null);
    const region = useRef(null);
    const gender = useRef(null);
    const mode = useRef(null);
    const startDate = useRef(null);
    const endDate = useRef(null);

    const queryKey = [
        `${baseApiUrl}s`,
        pagination.page,
        pagination.perPage,
        town.current,
        mode.current,
        isVerified.current,
        region.current,
        gender.current,
        startDate.current,
        endDate.current,
    ];

    const {
        register,
        reset,
        control,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm();

    const watchregion = watch("region", null);
    const watchStartDate = watch("startDate", null);
    const watchEndDate = watch("endDate", null);
    /**
     * Query
     */
    const {data: datas, isLoading} = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}${
            mode.current ? `&mode=${mode.current}` : ""
        }${town.current ? `&town=${town.current}` : ""}${
            isVerified.current ? `&isVerified=${isVerified.current}` : ""
        }${region.current ? `&region=${region.current}` : ""}${
            gender.current ? `&gender=${gender.current}` : ""
        }${watchStartDate ? `&startDate=${watchStartDate}` : ""}${watchEndDate ? `&endDate=${watchEndDate}` : ""}`,
        queryKey
    );

    const {data: total} = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?paginate=0`,
        queryKey
    );

    const cities = [
        {town: "Yaoundé", region: "Centre"},
        {town: "Mbalmayo", region: "Centre"},
        {town: "Bafia", region: "Centre"},
        {town: "Douala", region: "Littoral"},
        {town: "Edea", region: "Littoral"},
        {town: "Nkongsamba", region: "Littoral"},
        {town: "Bafoussam", region: "Ouest"},
        {town: "Dschang", region: "Ouest"},
        {town: "Foumban", region: "Ouest"},
        {town: "Bamenda", region: "Nord-Ouest"},
        {town: "Bafut", region: "Nord-Ouest"},
        {town: "Ndop", region: "Nord-Ouest"},
        {town: "Buea", region: "Sud-Ouest"},
        {town: "Limbe", region: "Sud-Ouest"},
        {town: "Kumba", region: "Sud-Ouest"},
        {town: "Ebolowa", region: "Sud"},
        {town: "Sangmelima", region: "Sud"},
        {town: "Kribi", region: "Sud"},
        {town: "Bertoua", region: "Est"},
        {town: "Garoua Boulai", region: "Est"},
        {town: "Abong Mbang", region: "Est"},
        {town: "Ngaoundere", region: "Adamaoua"},
        {town: "Mienganga", region: "Adamaoua"},
        {town: "Ngoundal", region: "Adamaoua"},
        {town: "Garoua", region: "Nord"},
        {town: "Figuil", region: "Nord"},
        {town: "Tcholliré", region: "Nord"},
        {town: "Maroua", region: "Extreme-Nord"},
        {town: "Kousseri", region: "Extreme-Nord"},
        {town: "Yagoua", region: "Extreme-Nord"},
    ];


    const handleSubmitFilterForm = (values: any) => {
        town.current = values.town?.name;
        region.current = values.region?.name;
        gender.current = values.gender?.name;
        mode.current = values.mode?.name;
        isVerified.current = values.isVerified?.id;
        startDate.current = values.startDate;
        endDate.current = values.endDate;
    };
    const handleCancelForm = () => {
        reset();
        town.current = null;
        isVerified.current = null;
        region.current = null;
        gender.current = null;
        mode.current = null;
        startDate.current = null;
        endDate.current = null;
    };

    const filteredCities = watchregion
        ? cities.filter((city) => city.region === watchregion.id)
        : cities;
    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Numéro de téléphone",
            key: "phone",
            dataIndex: "phone",
        },
        {
            title: "Date de naissance",
            key: "age",
            dataIndex: "age",
        },
        {
            title: "Genre",
            key: "gender",
            render: (element: any) => (
                <BadgeRounded
                    className={
                        element.gender === "F"
                            ? "bg-pink-600 text-white"
                            : "bg-blue-600 text-white"
                    }
                    text={element.gender === "F" ? "Femme" : "Homme"}
                />
            ),
        },
        {
            title: "Statut",
            key: "is_verified",
            render: (element: any) => (
                <BadgeRounded
                    className={
                        element.is_verified === "1"
                            ? "badge-soft-danger"
                            : "badge-soft-success"
                    }
                    text={element.is_verified === "1" ? "Non vérifié" : "Vérifié"}
                />
            ),
        },
        {
            title: "Région",
            key: "region",
            dataIndex: "region",
        },
        {
            title: "Mode",
            key: "subcription_mode",
            dataIndex: "subcription_mode",
        },
        {
            title: "Ville",
            key: "town",
            dataIndex: "town",
        },
        {
            title: "Date",
            key: "created_at",
            render: (element: any) => (
                <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
            ),
        },
    ];
    /**
     * UI
     */
    return (
        <>
            <Card title={"Filtres"} extras={[]}>
                <Form
                    loading={isLoading}
                    onSubmit={handleSubmit(handleSubmitFilterForm)}
                >
                    <div className={"grid lg:grid-cols-4 md:grid-cols-2 gap-2"}>
                        <div>
                            <InputDate
                                label={"Date de début"}
                                register={register}
                                error={errors?.startDate}
                                name={"startDate"}
                                defaultValue={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <InputDate
                                label={"Date de fin"}
                                register={register}
                                error={errors?.endDate}
                                name={"endDate"}
                                defaultValue={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Ce numéro est il vérifié"
                                control={control}
                                name={"isVerified"}
                                error={errors?.isVerified}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"id"}
                                items={[
                                    {id: 1, name: "Vérifié"},
                                    {id: 2, name: "Non vérifié"},
                                ]}
                                label="Statut"
                                required={false}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Selectionner la région"
                                control={control}
                                name={"region"}
                                error={errors?.region}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"name"}
                                items={cities
                                    .filter(
                                        (city, index, self) =>
                                            self.findIndex((c) => c.region === city.region) === index
                                    )
                                    .map((city) => ({
                                        id: city.region,
                                        name: city.region,
                                    }))}
                                label="Region"
                                required={false}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Selectionner la ville"
                                control={control}
                                name={"town"}
                                error={errors?.cities}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"id"}
                                items={filteredCities.map((city) => ({
                                    id: city.town,
                                    name: city.town,
                                }))}
                                label="Ville"
                                required={false}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Choisir votre genre"
                                control={control}
                                name={"gender"}
                                error={errors?.gender}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"name"}
                                items={[
                                    {id: 1, name: "H"},
                                    {id: 2, name: "F"},
                                ]}
                                label="Genre"
                                required={false}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Sélectionner le mode"
                                control={control}
                                name={"mode"}
                                error={errors?.mode}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"name"}
                                items={[
                                    {id: 1, name: "SMS"},
                                    {id: 2, name: "WEB"},
                                    {id: 2, name: "FACEBOOK"},
                                    {id: 2, name: "LINKEDIN"},
                                ]}
                                label="Mode"
                                required={false}
                            />
                        </div>
                        <div className={"flex items-end gap-2"}>
                            <SimpleButton
                                type={"submit"}
                                className="bg-soft-primary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center mt-2"
                            >
                                <BiSearchAlt2 className={"mr-2"}/> Filtrer
                            </SimpleButton>
                            <CancelButton onClick={handleCancelForm}/>
                        </div>
                    </div>
                </Form>
            </Card>
            <CardTable
                extras={[]}
                columns={columns}
                title={title}
                loading={isLoading}
                datas={datas?.data}
                pagination={pagination}
                setPagination={setPagination}
                tableClassName="text-center"
            />

        </>
    );
}

import { Navigate, useLocation } from "react-router-dom";
import NotFound from "../pages/error/404";
import { useAuth } from "../hooks/useAuth";
import { HOME_PAGE } from "../config/global";
import Redirect from "../components/Redirect";
import { AUTH_SIGN_IN_URL } from "../config/api";

export default function ManageRoute() {
    const auth = useAuth();
    const location = useLocation();
    return (auth ? location.pathname === "/" ? <Navigate to={HOME_PAGE} /> :
        <NotFound /> : <Redirect to={`${AUTH_SIGN_IN_URL}`} />
    );
}

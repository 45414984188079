import { UserRoles } from "./global";
import { BiChart, BiTable } from "react-icons/bi";

export interface MenuInterface {
  icon: React.FC;
  title: string;
  link: string;
  pathname: string;
  roles?: UserRoles[];
  subMenus?: MenuInterface[];
}

export const MENUS: MenuInterface[] = [
  {
    icon: BiChart,
    title: "Dashboard",
    link: "/dashboard",
    pathname: "dashboard",
  },
  {
    icon: BiTable,
    title: "Clients",
    link: "/clients",
    pathname: "clients",
  },
  
];

import { Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Redirect from "../components/Redirect";
import { AUTH_SIGN_IN_URL } from "../config/api";

export default function RequireAuth() {
  const auth = useAuth();
  return auth && auth.user ? (
    <Outlet />
  ) : (
    <Redirect to={`${AUTH_SIGN_IN_URL}`} />
  );
}
